.cards{
    border: none !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
}

.cards.content{
    padding: 0 !important;
}

.opTable.head{
    text-align: center;
}

.opTable.rows{
    text-align: center;
}

.opTable.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
}

.header.content{
    height: 72px;
    border-bottom: 3px solid #EBEBEB !important;
}

.header.rows.left50{
    padding-left: 32px;
    width: 50%;
}

.header.rows.right{
    padding-right: 32px;
    text-align: right;
}

.buttonBlue{
    background-color: #39aede !important;
    border-color: #39aede !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.buttonBlue:focus{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.buttonBlue:hover{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.file.element{
    position: relative;
    /* display: flex; */
    /* align-items: center; */
    width: 100%;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}

.map.element{
    position: relative;
    /* display: flex; */
    /* align-items: center; */
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}