.statusCard{
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    height: 130px !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
}

.statusCard.green{
    border-left: 5px solid #28A745 !important;
}

.statusCard.yellow{
    border-left: 5px solid #FFC008 !important;
}

.statusCard.red{
    border-left: 5px solid #DC3545 !important;
}

.cards{
    border: none !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
}

.table-bordered>:not(caption)>*{
    border-color: #EBEBEB !important;
}

.fc-list-day-side-text{
    text-decoration: none !important;
}