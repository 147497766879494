.cards{
    border: none !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
}

.cards.content{
    padding: 0 !important;
}

.opTable.head{
    text-align: center;
}

.cover.content.rows.title{
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}

.cover.content.rows.element{
    width: calc(100% - 50px);
    text-align: left;
    display: inline !important;
}

.cover.content.rows.searchbtn{
    margin-top: -1px !important
}

.opTable.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
}

.header.content{
    height: 72px;
    border-bottom: 3px solid #EBEBEB !important;
}

.header.rows.left{
    padding-left: 32px;
    width: 250px;
}

.header.rows.right{
    padding-right: 32px;
    text-align: right;
}

.buttonBlue{
    background-color: #39aede !important;
    border-color: #39aede !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.buttonBlue:focus{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.buttonBlue:hover{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.linkBlue{
    color: #39aede !important;
    text-decoration: none !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.linkBlue:focus{
    color: #0D72B5 !important;
}

.linkBlue:hover{
    color: #0D72B5 !important;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }
  
.dropzone:focus {
border-color: #2196f3;
}

.dropzone.disabled {
opacity: 0.6;
}

.react-datepicker__navigation.react-datepicker__navigation--previous{
    margin-top: 7px !important;
}

.react-datepicker__navigation.react-datepicker__navigation--next{
    margin-top: 7px !important;
}

.react-datepicker__triangle{
    left: -20px !important;
}