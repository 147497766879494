.cards{
    border: none !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
}

.fc-col-header-cell-cushion {
    text-decoration: none !important;
}

.fc-daygrid-day-number{
    text-decoration: none !important;
}

.table-bordered>:not(caption)>*>*{
    border: none !important;
}

.btn-primary{
    background-color: #39aede !important;
    border-color: #39aede !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.btn-primary:focus{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.btn-primary:hover{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.fc-h-event{
    background-color: #39aede !important;
    padding-left: 5px !important;
}

.rct-header-root{
    background: #39aede !important;
}

.rct-item{
    background-color: #39aede !important;
    padding-left: 0px !important;
    border-radius: 3px !important;
    border: 1px solid var(--fc-event-border-color, #3788d8) !important;
}

.rct-calendar-header{
    border: none !important;
}