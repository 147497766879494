.menuNotActive{
    text-decoration: none;
    background-color: #39AEDE !important;
    padding-top: 9px;
    padding-bottom: 11px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.menuNotActive:focus{
    text-decoration: "none";
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.menuNotActive:hover{
    text-decoration: "none";
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.menuActive{
    text-decoration: none;
    background-color: #0D72B5 !important;
    padding-top: 9px;
    padding-bottom: 11px;
}